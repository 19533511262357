import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const KanbanBoard = (props) => {
  const taskDetails = Array.isArray(props?.taskDetails) ? props.taskDetails : [];

  // Create the kanbanData grouped by task status
  const kanbanData = taskDetails.reduce((acc, task) => {
    const status = task.taskStatus || "No Status";
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(task);
    return acc;
  }, {});

  const columnOrder = Object.keys(kanbanData).filter((key) => Array.isArray(kanbanData[key]));
  const [columns, setColumns] = React.useState(columnOrder);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside of any droppable area, return
    if (!destination) return;

    // If dragged to the same position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    // Rearrange the columns
    const updatedColumns = Array.from(columns);
    const [removed] = updatedColumns.splice(source.index, 1);
    updatedColumns.splice(destination.index, 0, removed);

    setColumns(updatedColumns);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="kanban-board" direction="horizontal" type="COLUMN">
        {(provided) => (
          <div className="kanban-board"
            ref={provided.innerRef} 
            {...provided.droppableProps}>

            {columns.length > 0 ? (
              columns.map((status, index) => (
                <Draggable draggableId={status} key={status} index={index}>
                  {(provided) => {
                    const tasks = Array.isArray(kanbanData[status])
                      ? kanbanData[status]
                      : [];
                    const assignedTaskCounts = tasks.reduce((acc, task) => {
                      const assignee = task?.assignedTo?.displayName || "Unassigned";
                      acc[assignee] = (acc[assignee] || 0) + 1;
                      return acc;
                    }, {});

                    return (
                      <div key={status} 
                        className="kanban-column"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className="kanban-column-header">
                          {status === "new" && <FontAwesomeIcon icon='list' style={{ color: '#326fd1' }} />}
                          {status === "in development" && <FontAwesomeIcon icon='spinner' style={{ color: '#9fa9b7' }} />}
                          {status === "dev completed" && <FontAwesomeIcon icon='check' style={{ color: '#198754' }} />}
                          {status === "analysis/design" && <FontAwesomeIcon icon='search' style={{ color: '#00FF00' }} />}
                          {status === "review completed" && <FontAwesomeIcon icon='eye' style={{ color: '#6366f1' }} />}
                          {status === "ready for testing" && <FontAwesomeIcon icon='briefcase' style={{ color: '#eec137' }} />}
                          {status === "need clarification" && <FontAwesomeIcon icon='play' style={{ color: '#FF00FF' }} />}
                          {status === "reopen" && <FontAwesomeIcon icon='undo' style={{ color: 'red' }} />}
                          {status === "resolved" && <FontAwesomeIcon icon='thumbs-up' style={{ color: '#0dcaf0' }} />}
                          {status === "moved to prod" && <FontAwesomeIcon icon='cloud' style={{ color: '#00FFFF' }} />}
                          {status === "onhold" && <FontAwesomeIcon icon='ban' style={{ color: '#000000' }} />}
                          {status === "future implementation" && <FontAwesomeIcon icon='bookmark' style={{ color: '#000080' }} />}
                          {status === "unable to replicate" && <FontAwesomeIcon icon='exclamation-triangle' style={{ color: '#A52A2A' }} />}
                          {status === "duplicate" && <FontAwesomeIcon icon='clone' style={{ color: '#808080' }} />}
                          {status === "not an issue" && <FontAwesomeIcon icon='flag' style={{ color: '#FFA500' }} />}
                          &nbsp;&nbsp;
                          {status.toUpperCase()} ({tasks.length})
                        </div>
                        <div className="kanban-column-content mb-3">
                          {tasks.length > 0 ? (tasks.map((task) => (
                              <div key={task._id} className={`kanban-card ${status}`}>
                                <div className="kanban-card-header">
                                  <span className="kanban-task-id"
                                    onClick={() => {
                                      props.setOpenTaskDetailsDialog?.(true);
                                      props.setVisibleTaskDetails?.(true);
                                      props.setNeededTaskID?.(task.taskID);
                                    }}>
                                    {task.taskID}
                                  </span>
                                  <span className="kanban-assignee" title={task?.assignedTo?.displayName}>
                                    {task?.assignedTo?.displayName?.[0] || "E"}
                                  </span>
                                </div>
                                <div className="kanban-card-title">
                                  {task.name || "No Title"}
                                </div>
                                <div className="kanban-card-details">
                                  {task.estimatedHours && (
                                    <div className="kanban-detail">
                                      Estimated Hours: {task.estimatedHours}
                                    </div>
                                  )}
                                  {task.actualWorkingHours && (
                                    <div className="kanban-detail">
                                      Actual Working Hours:{" "}
                                      {task.actualWorkingHours}
                                    </div>
                                  )}
                                  {task.taskCompletionPercent !== undefined && (
                                    <div className="kanban-detail">
                                      Task Completion:{" "}
                                      {task.taskCompletionPercent}%
                                    </div>
                                  )}
                                </div>
                                <div className="kanban-card-footer mt-1">
                                  {task.endDate && (
                                    <span className="tag tag-improvement">
                                      End-Date: {task.endDate}
                                    </span>
                                  )}
                                </div>
                                <div className="kanban-card-footer-end mt-1">
                                  <span className="tag tag-assignName">
                                    {task?.assignedTo?.displayName || "Unassigned"}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="kanban-card no-tasks">
                              No tasks for this status
                            </div>
                          )}
                        </div>
                        <div className="kanban-column-footer">
                          {Object.entries(assignedTaskCounts).length > 0 ? (
                            Object.entries(assignedTaskCounts).map(
                              ([assignee, count]) => (
                                <div key={assignee} className="kanban-assignee-summary">
                                  {assignee}: {count} task(s)
                                </div>
                              )
                            )
                          ) : (
                            <div className="kanban-assignee-summary">
                              No Assignees
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              ))
            ) : (
              <div className="kanban-column no-tasks">No Tasks Found</div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default KanbanBoard;
